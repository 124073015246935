<template>
    <div class="product">
       <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">银行信贷产品</div>
        <div class="header flex">
        <div class="search-box flex">
            <img src="@/assets/img/serch.png" @click="searchSubmit">
            <input type="text" placeholder="点击进入信贷产品搜索" v-model="apiProductListInfo.creditName" @keyup.enter="searchSubmit">
        </div>
        <div class="screen flex" @click="showPopup">
            <p>高级筛选</p>
            <img src="../../assets/img/screen_w.png" alt="">
        </div>
        </div>
        <van-popup class="sizer" v-model:show="show" round position="top" :style="{ height: '90%' }">
          <Sizer @searchClick="searchClick"></Sizer>
        </van-popup>
        <div class="up-down flex-between">
            <div class="flex" @click="orderChange(1)">
                <p>成交量</p>
                <div v-if="apiProductListInfo.reqOrder == '1'">
                  <img src="../../assets/img/up.png">
                </div>
                <div v-else-if="apiProductListInfo.reqOrder == '2'">
                  <img src="../../assets/img/down.png" alt="">
                </div>
                <div v-else>
                  <img src="../../assets/img/down.png" alt="">
                  <img src="../../assets/img/up.png" alt="">
                </div>                
            </div>
            <div class="flex" @click="orderChange(2)">
                <p>额度</p>
                <div v-if="apiProductListInfo.amtOrder == '1'">
                  <img src="../../assets/img/up.png">
                </div>
                <div v-else-if="apiProductListInfo.amtOrder == '2'">
                  <img src="../../assets/img/down.png" alt="">
                </div>
                <div v-else>
                  <img src="../../assets/img/down.png" alt="">
                  <img src="../../assets/img/up.png" alt="">
                </div>    
            </div>
            <div class="flex" @click="orderChange(3)">
                <p>利率</p>
                <div v-if="apiProductListInfo.perOrder == '1'">
                  <img src="../../assets/img/up.png">
                </div>
                <div v-else-if="apiProductListInfo.perOrder == '2'">
                  <img src="../../assets/img/down.png" alt="">
                </div>
                <div v-else>
                  <img src="../../assets/img/down.png" alt="">
                  <img src="../../assets/img/up.png" alt="">
                </div>    
            </div>

        </div>
        <div class="contrast">
            <div class="top-bg">
                <img src="../../assets/img/duibi.png" alt="">
            </div>            
            <div class="flex-between" v-for="(item,index) in listCon" :key="item">
              <p>{{item.creditName}}<span>({{item.orgName}})</span></p> <img @click="remove(index)" src="../../assets/img/del.png" alt="">
            </div>
            <div class="btn">
                <div @click="compareProduct" class="yellowc">对比产品</div>
                <div @click="applyRequirement" class="bluec">申请需求</div>
            </div>
        </div>
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >        
          <div class="goods-info" v-for="item in creditProInfo" :key="item.productId"  @click="goLoanContent(item.productId,item.loanedCnt,item.bankImgUrl)">
            <div class="goods-header flex">
              <img :src="store.state.imgURL + item.bankImgUrl">    
              <div class="flex1">{{item.creditName}}</div>
              <p class="gray">放款{{item.loanedCnt ? item.loanedCnt : 0}}笔</p>
            </div>
            <div class="goods-content flex">
              <img :src="store.state.imgURL + item.imgUrl" alt="">
              <div class="flex-col">
                <!-- <p>适用银行：{{item.orgName}}</p> -->
                <p>融资额度：{{item.minLimitAmt ? item.minLimitAmt+'~'+item.maxLimitAmt + '万': '无限制'}}</p>
                <p>融资金额期限：{{item.minYears ? item.minYears+'~'+item.maxYears + '个月': '无限制'}}</p>
                <!-- <p>融资利率：{{item.minRate ? item.minRate+'%~'+item.maxRate + '%': '优惠利率'}}</p> -->
                <p v-if="item.rate">融资利率：{{item.maxRate? item.minRate+'%~'+item.maxRate + '%': item.minRate+'%'}}</p>
                <p v-else>融资利率：{{item.rateOtherContent}}</p>
              </div>
            <div class="star flex">
              <img src="../../assets/img/star.png" alt="">
              <img src="../../assets/img/star.png" alt="">
              <img src="../../assets/img/star.png" alt="">
              <img v-show="parseInt(item.loanedCnt) > 0" src="../../assets/img/star.png" alt="">
              <img v-show="parseInt(item.loanedCnt) > 5" src="../../assets/img/star.png" alt="">
            </div>
            <div class="share flex" @click.stop="showShare = true">
              <img src="../../assets/img/share.png" alt="">
              <p>分享</p>
            </div>
            <!-- <router-link :to="'/apply2/'+item.productId" class="apply">我要申请</router-link> -->
            <div class="apply" @click.stop="applyRequirement2(item.productId)">我要申请</div>
            </div>
            <div class="goods-footer flex-between">
                <div class="goods-btn flex">
                    <div class="collect flex" @click.stop="collect(item.productId,item.cancelCollectFlag)">
                        <van-icon :name="item.cancelCollectFlag==='0'?'star':'star-o'"/><span>收藏</span>
                    </div>
                    <div class="compare flex" @click.stop="contrast(item.productId)">
                        <img src="../../assets/img/duibi_icon.png" alt="">
                        <span>对比</span>
                    </div>
                </div>
                <div class="detail">详情>></div>
            </div>
        </div>
        </van-list>
        
       
       <van-share-sheet
  v-model:show="showShare"
  title="立即分享给好友"
  :options="shareOptions"
  @select="onSelect"
/>
    </div>
      <van-popup v-model:show="showQR">
    <QrcodeVue :value="showQRurl" :size="256" style="padding: 5px"></QrcodeVue>
    <div class="downImg" @click="downloadAppCodeImg">点击下载二维码</div>
  </van-popup>
</template>
<script>

import { Toast,Dialog } from 'vant';
import Sizer from '../../components/Sizer.vue';
import { ref,reactive,toRaw} from 'vue';
import { useStore } from 'vuex';
import {useRouter} from 'vue-router';
import { apiProductList,apiCollect } from '../../api/axios';
import useClipboard from 'vue-clipboard3';
import QrcodeVue from 'qrcode.vue';
export default {
    
    setup() {
      let store = useStore();
      let router = useRouter();
      const creditProInfo = reactive([]);
      const totalPage = ref(0);
      let searchVal = {};
      const loading = ref(false);
      const finished = ref(false);
      const apiProductListInfo = reactive({type: '0',pageNo: 1,pageCityCode: '450000',creditName:''});
      // 挂载
      // onMounted(() => {
      // })
      apiProductListInfo.creditName = router.currentRoute.value.query.searchVal;
      const onLoad = () => {
          var loadVal = extendJson(toRaw(apiProductListInfo),searchVal);
          apiProductList(loadVal).then(res => {                 
             if(res.code == '1'){
                 res.info.creditProInfo.forEach(e => {
                  creditProInfo.push(e);
                });                
                if(apiProductListInfo.pageNo === 1) {
                  // store.dispatch('setQueryInfo',res.info.queryInfo);
                  totalPage.value = res.info.page.totalPage;
                  finished.value = false;//加载设置为未完成
                }
                // 数据全部加载完成
                if(apiProductListInfo.pageNo >= totalPage.value){
                  finished.value = true;
                }else {
                  apiProductListInfo.pageNo++;
                  // 加载状态结束
                  loading.value = false;
                }
              }
              else {
                Toast(res.msg);
              }
          });           
      };
      
      // 收藏
      const collect = (id,flag) => {
         let obj = {
          proId: id.toString(),
          cancelCollectFlag: '0',
        }   
        if(store.state.token) {             
        if(flag === '0') {
          obj.cancelCollectFlag = '1';
        }
        apiCollect(obj).then(res => {                
             if(res.code == '1'){
               for(let i=0; i< creditProInfo.length; i++) {
                  if(creditProInfo[i].productId == id) {
                    creditProInfo[i].cancelCollectFlag = obj.cancelCollectFlag;
                    break;
                  }
                }               
              }
              else {
                Toast(res.msg);
              }
        });

          }else {
            Dialog.confirm({
              title: '提示！',
              confirmButtonText: '去登录',
              message:
                '您还没有登录！点击下方按钮去登录。',
              })
              .then(() => {
                jumpLogin();
              })
              .catch(() => {
                console.log('取消登录!')
              });
          }
   
      };
      //对比框
      const listCon = reactive([]);
      // listCon = store.state.contrastList;
      store.state.contrastList.forEach(e => {
        listCon.push(e);
      });
      const remove = (i) => {
        listCon.splice(i,1);
        store.dispatch('setSpliceContrastList',i);
      };
      const contrast = (id) => {
        if(listCon.length < 3){
          let arrIndex = listCon.findIndex(item => {
            return item.productId === id
          });
          if (arrIndex > -1) {
            Toast('已经在对比列表中啦');
          } else {
            let obj = toRaw(creditProInfo).find(o => o.productId === id);
            listCon.push(obj);
            store.dispatch('setContrastList',obj);
          }          
        }else {
          Toast('最多只能对比3个');
        }        
      };
      const compareProduct = () =>{
        if(listCon.length === 0) {
           Toast('您还没有选择对比产品！');
           return
        }
        const proId = [];
        toRaw(listCon).forEach(e => {
            proId.push(e.productId);
        });
        router.push({
          path: '/productContrast',
          query: {
            proIds: proId.toString()
          }
        });
      };
      // 申请需求
      const applyRequirement = () =>{
        if(!listCon.length) {
            Toast('您还没有选择对比产品！');
            return
        }
        const token = store.state.token;        
        if(token) {          
          const proId = [];
          toRaw(listCon).forEach(e => {
              proId.push(e.productId);
          });
          router.push({
            path: '/apply',
            query: {
              proIds: proId.toString()
            }
          });
        }else {
          Dialog.confirm({
            title: '提示！',
            confirmButtonText: '去登录',
            message:
              '您还没有登录！点击下方按钮去登录。',
            })
            .then(() => {
              jumpLogin();
            })
            .catch(() => {
              console.log('取消登录!');
          });
        }
        
      };
      // 我要申请
      const applyRequirement2 = (id) =>{
        const token = store.state.token;        
        if(token) {          
          router.push({
            path: '/apply',
            query: {
              proIds: id
            }
          });
        }else {
          Dialog.confirm({
            title: '提示！',
            confirmButtonText: '去登录',
            message:
              '您还没有登录！点击下方按钮去登录。',
            })
            .then(() => {
              jumpLogin();
            })
            .catch(() => {
              console.log('取消登录!')
          });
        }
        
      };
      //高级筛选
      const show = ref(false);
      const showPopup = () => {
        show.value = true;
      };
      function extendJson(target, source) {
            for (var obj in source) {
                target[obj] = source[obj];
            }
            return target;
      }
      function reloadApi() {
        apiProductListInfo.pageNo = 1; //将当前页重置为1
        totalPage.value = 0; //总页数为空
        creditProInfo.splice(0,creditProInfo.length);//清空原有的数据
        
        // finished.value = false;//加载设置为未完成
        onLoad();//重新加载数据
      }
      function searchClick (searCon) {        
        searchVal = searCon;//保存搜索框的值
        reloadApi(); //重新加载数据 
        show.value = false;//关闭弹窗
      }
      //成交量升序/降序
      const orderChange = (val) => {
        if(val === 1) {
          switch (apiProductListInfo.reqOrder) {
            case '1':
                apiProductListInfo.reqOrder = '2';
                reloadApi();
                break;
            case '2':
                delete apiProductListInfo.reqOrder;
                reloadApi();
                break;
            default:
                apiProductListInfo.reqOrder = '1';
                reloadApi();
          }
        }else if(val === 2){
          switch (apiProductListInfo.amtOrder) {
            case '1':
                apiProductListInfo.amtOrder = '2';
                reloadApi();
                break;
            case '2':
                delete apiProductListInfo.amtOrder;
                reloadApi();
                break;
            default:
                apiProductListInfo.amtOrder = '1';
                reloadApi();
          }
        }else {
          switch (apiProductListInfo.perOrder) {
            case '1':
                apiProductListInfo.perOrder = '2';
                reloadApi();
                break;
            case '2':
                delete apiProductListInfo.perOrder;
                reloadApi();
                break;
            default:
                apiProductListInfo.perOrder = '1';
                reloadApi();
          }
        }
        // console.log(val);
      };
      const searchSubmit = () => {
      // console.log('搜索值为：');
      searchVal.creditName = apiProductListInfo.creditName;
      reloadApi();
      
      };
      //分享
      const showShare = ref(false);
      const showQR = ref(false);
      const showQRurl = ref('http://yhapp.gaoliuxu.com/#/');
    const shareOptions = [
      // { name: '微信', icon: 'wechat' },
      // { name: '微博', icon: 'weibo' },
      { name: '复制链接', icon: 'link' },
      { name: '二维码', icon: 'qrcode' },
    ];
    const onSelect = (option) => {
      // console.log(option.name);
      let urls = window.location.href;
      if(option.name==='复制链接') {
        copy(urls);//复制到剪切板
        showShare.value = false;
      }else {
        showShare.value = false;
        showQR.value = true;
        showQRurl.value = urls;        
      }
    };
    const { toClipboard } = useClipboard()
    const copy = async (Msg) => {
      try {
      	//复制
        await toClipboard(Msg)
        // console.log(Msg)
        Toast('已复制到剪切板');
      } catch (e) {
      	//复制失败
        // console.error(e)
        Toast('复制失败');
      }
    }

    //产品详情
      const goLoanContent = (id,cnt,url) =>{
        let obj = {
          productId: id.toString(),
          loanedCnt: cnt,
          bankImgUrl: url
        }
        let obj2 = JSON.stringify(obj);
        // console.log(obj2);
        router.push({
          path: '/loanProductContent',
          query: {
            proIds: encodeURIComponent(obj2)
          }
        });        
      };
      //跳转登录页
      const jumpLogin = () => {
        if(store.state.isiOS) {
          try {
            window.webkit.messageHandlers.ocLogin.postMessage(null);
          } catch (e) {
            alert('ios跳转登录调用失败');
          }
        }else {
          router.push({path: '/login'});
        }
      }
      const downloadAppCodeImg = ()=>{
       let canvas = document.getElementById('app').getElementsByTagName('canvas')
      let a = document.createElement('a')
      a.href = canvas[0].toDataURL('img/png')
      a.download = '二维码.png'
      a.click()
    }

      return {
        store,
        creditProInfo,
        onLoad,
        loading,
        finished,
        listCon,
        remove,
        collect,
        contrast,
        show,
        showPopup,
        searchClick,
        searchSubmit,
        orderChange,
        shareOptions,
        onSelect,
        showShare,
        showQR,
        showQRurl,
        apiProductListInfo,
        compareProduct,
        applyRequirement,
        applyRequirement2,
        goLoanContent,
        downloadAppCodeImg
      }
    },
    methods:{
        goBack(){
            this.$router.go(-1);
        },
    },
    components: {
    Sizer,
    QrcodeVue
    }

    
}
</script>
<style lang="less" scoped>
.product {
    padding-top: 12.5vw;    
   .header {
    background: linear-gradient(to right, #65b8fc, #8193ff);
    padding: 0 4vw 2vw;    
    .screen {
      padding: 2vw;
      border-radius: 1vw;
      border: 1px solid #fff;
      margin-left: 2vw;
      p {
          color: #fff;
          font-size: 4vw;
          line-height: 4vw;
      }
      img {
        width: auto;
        height: 4vw;
        margin-left: 2vw;
      }
    }    
    }
    .up-down {
        padding: 2.5vw 4vw;
        background-color: #fff;
        .flex {
            p {
                font-size: 4vw;
            }
            div {
              width: 6vw;
              line-height: 3vw;
              margin-left: 1vw;
              img {
                width: 3vw;
                height: auto;
                
              }
            }
            

        }
    }
    .contrast {
        margin: 8vw 4vw 4vw;
        background-color: #fff;
        border-radius: 2vw;
        border: 1px solid #ff8d3f;
        padding: 8vw 4vw 2vw;
        position: relative;
        .top-bg{
            position: absolute;
            width: 100%;
            text-align: center;
            top: -3.8vw;
            left: 0;
            img {
                width: 44.3vw;
            }
        }
        .flex-between {
            background-color: #fbf2eb;
            padding: 2vw 4vw;
            border-radius: 1.5vw;
            margin: 2vw 0;
            p{
                font-size: 4vw;
                font-weight: 600;
                span {
                    font-weight: normal;
                    color: #666;
                    padding-left: 2vw;
                }
            }
            img {
                height: 5vw;
                width: auto;
            }
        }
        .btn {
            display: flex;
            justify-content: center;  
            .bluec {
                background: linear-gradient(to right, #65b8fc, #8193ff);
                color: #fff;
                font-size: 4.8vw;
                // font-weight: 500;
                padding: 2vw 6vw;
                border-radius: 6vw;
                margin: 4vw 2vw;
            }
            .yellowc {
                background: linear-gradient(to right, #ff9900, #ff6700);
                color: #fff;
                font-size: 4.8vw;
                // font-weight: 500;
                padding: 2vw 6vw;
                border-radius: 6vw;
                margin: 4vw 2vw;
            }
        }
    }
    .goods-info {
    margin: 3vw 4vw;
    background-color: #fff;
    border-radius: 10px;
    padding: 3vw 2.5vw;
    .goods-header {
      margin-bottom: 2vw;
      img,span {
        width: 28.6vw;
        height: 8vw
      };
      span {
        font-size: 4vw;
        line-height: 8vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .flex1 {
        flex: 1;
        margin-left: 2vw;
        font-size: 4.8vw;
        color: #ff9900;
        font-weight: 600;
      }
      .gray {
        font-size: 3.2vw;
        color: #666666;
      }
    }
    .goods-content {
      position: relative;
      img {
        height: 18.7vw;
        width: 28.6vw;
      }
      .flex-col {
        margin-left: 2vw;
        p {
          margin: 0;
          font-size: 3.2vw;
          color: #666666;
          margin: 0.5vw;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 40vw;
        }
      }
      .star {
        position: absolute;
        top: -3vw;
        right: 0;
          img {
            width: 4vw;
            height: 4vw;
            margin-left: 1vw;
          }
      }
      .share {
        position: absolute;
        top: 4vw;
        right: 0;
        border: 1px solid #ff9900;
        padding: 1vw 3vw;
        border-radius: 4vw;
          img {
            width: 3.6vw;
            height: auto;
            margin-right: 1vw;
          }
          p {
            color: #ff9900;
            font-size: 3.2vw;
          }
      }
      .apply {
        position: absolute;
        bottom: 0;
        right: 0;
        background: linear-gradient(to right, #ff9900, #ff6700);
        color: #fff;
        font-size: 3.5vw;
        padding: 1vw 2vw;
        border-radius: 4vw;
      }

    }
    .goods-footer {
        margin-top: 2vw;
        .goods-btn {
            .collect {
                font-size: 4vw;
                color: #0099ff;
                border: 2px solid #0099ff;
                padding: 1vw 2vw;
                border-radius: 4vw;
                span {
                font-size: 3.2vw;
                color: #0099ff;
                margin-left: 1.5vw;
                }
            }
            .compare {
                border: 2px solid #ff6666;
                padding: 1vw 2vw;
                border-radius: 4vw;
                margin-left: 2vw;
                img {
                    height: 4vw;
                    width: auto;
                }
                span {
                font-size: 3.2vw;
                color: #ff6666;
                margin-left: 1.5vw;
                }
            }
        }
        .detail {
            color: #ff6800;
            font-size: 4vw;
        }
    }

  }
}
</style>
